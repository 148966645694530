export default {
  fonts: {
    title: "Rubik, sans-serif",
    main: "Karla, sans-serif",
  },
  colors: {
    primary1: "hsl(176, 100%, 20%)",
    primary2: "hsl(185, 100%, 28%)",
    accent1: "hsl(151, 82%, 68%)",
    accent2: "hsl(279, 7%, 60%)",
    dark: "hsl(186, 100%, 10%)",
    light1: "hsl(210, 17%, 98%)",
    light2: "hsl(0, 0%, 100%)",
  },
  breakpoints: {
    mobile: "only screen and (max-width: 507px)",
    tablet: "only screen and (max-width: 805px)",
    laptop: "only screen and (max-width: 1028px)",
  },
}

// import { red } from "@material-ui/core/colors"
// import { createMuiTheme } from "@material-ui/core/styles"

// const Default = createMuiTheme({
//   palette: {
//     primary: {
//       main: "hsl(151, 82%, 68%)",
//       contrastText: "hsl(186, 100%, 10%)",
//     },
//     secondary: {
//       main: "hsl(185, 100%, 28%)",
//       contrastText: "hsl(186, 100%, 10%)",
//     },
//     error: {
//       main: red.A400,
//     },
//     background: {
//       default: "hsl(195, 50%, 95%)",
//     },
//   },
// })
